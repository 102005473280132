<template>
  <v-bottom-navigation 
  v-model="value" 
  fixed 
  app
  color="primary" 
  class="primary pt-2"
  grow>
    <v-btn value="home" to="/home" color="primary">
      <span style="color:#fff !important;">Home</span>
      <v-icon color="white">mdi-home</v-icon>
    </v-btn>

    <v-btn value="projects" to="/projects" color="primary">
      <span style="color:#fff !important;">Projects</span>
      <v-icon color="white">mdi-layers-triple</v-icon>
    </v-btn>

    <v-btn value="checklists" to="/checklists" color="primary">
      <span style="color:#fff !important;">Checklists</span>
      <v-icon color="white">mdi-clipboard-check-multiple</v-icon>
    </v-btn>

    <v-btn value="incident" to="/safety" color="primary">
      <span style="color:#fff !important;">Safety</span>
      <v-icon color="white">mdi-flag-variant</v-icon>
    </v-btn>

    <v-btn value="apps" to="/apps" color="primary">
      <span style="color:#fff !important;">Apps</span>
      <v-icon color="white">mdi-tooltip-plus-outline</v-icon>
    </v-btn>
  </v-bottom-navigation>
</template>

<script>
  export default {
      name: "BottomNavigation",
      data: () => ({ value: 'recent' }),
  }
</script>