<template>
    <div>
        <v-container class="text-center pa-8 mt-1">
            <img src="../assets/camusat_fms2_logo.png" style="width:255px;margin-top:60px;"/>
            <h3 class="primary--text mt-3">Please Login</h3>

            <v-card
            outlined 
            class="blue lighten-5 mt-5 pa-4">
                <v-text-field
                    label="Email"
                    :rules="rules"
                    hide-details="auto"
                    v-model="email"
                    outlined
                ></v-text-field>
                <v-text-field 
                    label="Password" 
                    :rules ="rules"
                    v-model="password"
                    outlined
                    class="pt-3"
                    type="password"
                ></v-text-field>
                <v-btn @click="loginSubmit" class="primary">Login</v-btn>
                <p @click="resetSheet = !resetSheet" class="mt-7">Reset Password</p>
            </v-card>
            <p style="position: fixed;left: 0;bottom: 0; width:100%; text-align:center;">Powered by <strong>Ubuni</strong>Works</p>
            <v-bottom-sheet v-model="resetSheet">
                <v-sheet class="text-center  pa-4 " height="300px">
                    <h2  class="primary--text mt-7">Reset Password</h2>
                    <v-text-field
                    label="Email"
                    :rules="rules"
                    hide-details="auto"
                    v-model="resetemail"
                    outlined
                ></v-text-field>
                    <v-btn class="mt-6 primary" text @click="resetSubmit">Reset Password</v-btn>
                    <v-btn class="mt-6" text color="red" @click="resetSheet = !resetSheet">Close</v-btn>
                </v-sheet>
            </v-bottom-sheet>
        </v-container>
    </div>
</template>

<script>
import Swal from 'sweetalert2'
export default {
    name: "Login",
    data: () => ({
        resetSheet: false,
        email: "",
        resetemail: "",
        password: "",
        rules: [
            value => !!value || 'Required.',
            value => (value && value.length >= 4) || 'Min 4 characters',
      ]
    }),
    methods:{
        async loginSubmit () {
            const requestOptions = {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ 
                    email: this.email,
                    password: this.password
                })
            };
            const res = await fetch('https://camusatweb.ubuniworks.com/api/v1/auth/login', requestOptions)
            const data = await res.json()
            if(data.status == true){
                localStorage.setItem('user', JSON.stringify(data.user))
                localStorage.setItem('userDate',Date.now())
                localStorage.setItem("timesheet", data.user.timesheet)
                localStorage.setItem("timesheetState", data.user.state)
                localStorage.setItem("timesheetStateData", data.user.state_data)
                Swal.fire({
                    icon: 'success',
                    title: 'User successfully logged in'
                })
                location.reload()
            }else{
                Swal.fire({
                    icon: 'error',
                    title: 'Login failed',
                    text: data.message
                })
            }
        },
        async resetSubmit () {
            const requestOptions = {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ 
                    email: this.resetemail
                })
            };
            const res = await fetch('https://camusatweb.ubuniworks.com/api/v1/auth/reset', requestOptions)
            const data = await res.json()
            if(data.status == true){
                Swal.fire({
                    icon: 'success',
                    title: 'Password reset successfully. Check email or SMS for password.'
                })
                this.resetSheet = false;
            }else{
                Swal.fire({
                    icon: 'error',
                    title: 'Password reset failed',
                    text: data.message
                })
            }
        }
    },
    mounted(){
        let user = JSON.parse(localStorage.getItem('user'))
        if(user != null){
            this.$router.push('/home')
        }
    }
}
</script>

<style scoped>

</style>